<template>
  <div>
    <div class="alert alert-danger mt-5">
      <h4>#нетвойне</h4>
      I
      <a href="https://www.change.org/NetVoyne">condemn</a>
      the war that Russian government started against Ukraine.
    </div>

    <div class="text-general row">
      <a class="col-sm mb-3" :href="`${publicPath}photo.jpg`">
        <img
          alt="photo"
          :src="`${publicPath}photo_small.jpg`"
          width="400"
          class="img-thumbnail"
        />
      </a>

      <div class="col-md-8">
        <h1>Boris Kirikov</h1>

        <p class="lead">
          I am a python backend developer, currently working in
          <a href="https://adjust.com">Adjust</a> at
          <a
            href="https://www.adjust.com/product-updates/get-ready-for-adjust-automate/"
            >Automate</a
          >
          project. Now living in Berlin.
        </p>

        <p class="lead">
          I have 8 years of software development experience gained from work on
          various projects in different areas.
        </p>

        <p class="lead">
          I enjoy designing and implementing complex systems, writing good code
          and creating useful software.
        </p>

        <p class="lead">
          Also I am interested in system programming, networking and
          computer security.
        </p>

        <p class="lead">
          Check out my <v-link section="cv">CV</v-link> for more information.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import VLink from "@/navigation/VLink";
export default {
  name: "Home",
  components: { VLink },
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
};
</script>

<style scoped></style>
